.page-title {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.marginBottom {
  margin-bottom: 10px;
}
