.avatar-title {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.categories-container {
  height: calc(100vh - 250px);
  min-height: 200px;
  overflow: auto;
}
.category-container {
  margin: 5px;
  padding: 10px;
  border: 1px dotted #ccc;
  cursor: pointer;
}
.avatar-svg-containers {
  display: flex;
  flex-wrap: wrap;
  min-height: 30px;
}
.avatar-svg-container {
  width: 50px;
  height: 50px;
  overflow: hidden;
  margin: 2px;
  display: inline-grid;
  position: relative;
  cursor: pointer;

  &-base-set {
    @extend .avatar-svg-container;
    outline: 1px solid rgb(218, 218, 218);
  }
  &-base-set-applied {
    @extend .avatar-svg-container-base-set;
    background-color: rgb(239, 255, 246);
  }
  &-no-action {
    @extend .avatar-svg-container;
    cursor: default;
  }
}
.avatar-svg-container:hover {
  outline: 1px solid black;
}
.avatar-svg-container-no-action:hover {
  outline: 0;
}

.category-label-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.avatar-part-selected {
  outline: 2px solid blue;
}

.avatar-detail-svg {
  width: 200px;
  height: 200px;
  border: 1px solid #ccc;
  z-index: 100;
  position: absolute;
  overflow: hidden;
}
.avatar-selected-svg {
  background-color: lightgrey;
  width: 200px;
  height: 200px;
  border: 1px solid #ccc;
  overflow: hidden;
}
.avatar-secondary-svg {
  width: 200px;
  height: 200px;
  border: 1px solid #ccc;
  background-color: lightgrey;
}
.detail-containers {
  margin-left: 10px;
}
.section-label-container {
  margin-bottom: 10px;
}
.detail-container {
  overflow: hidden;
}
.more-icon {
  position: absolute;
  margin-top: 5px;
}

.schedule-container {
  display: flex;
  flex-direction: row;
}
.schedule-new-container {
  align-self: center;
  cursor: pointer;
  width: 80px;
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
.schedule-new-container:hover {
  opacity: 1;
}

.schedule-block {
  padding: 5px;
  min-height: 60px;
  flex: 1;
  cursor: pointer;
  font-size: larger;
  display: flex;
  flex-direction: column;

  &-selected {
    @extend .schedule-block;
    outline: 1px dotted rgb(218, 218, 218);
  }
}

.schedule-point-label {
  margin-bottom: 10px;
}
.schedule-point-label:hover {
  text-decoration: underline;
}
