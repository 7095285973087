@import '../../../styles/variables';

.string-lookup-collapse-panel {
  .fr-sticky-on {
    top: 50px !important;
  }

  .ant-collapse-header {
    z-index: 11;
    position: sticky !important;
    top: 0px;
    min-height: 40px;
    background-color: #fafafa;
  }

  .string-lookup-action {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    margin: 0 10px;

    span {
      margin-right: 4px;
    }

    &.saved {
      color: #52c41a !important;
    }
  }
}
