@import './styles/variables';

body {
  font-family: 'Nunito Sans', 'sans-serif';
}

#main {
  background-color: #181929;
}

#content {
  margin: 20px;
  background-color: #181929;

  .title {
    font-size: x-large;
    margin-bottom: 10px;
  }
}

.tr-even-color {
  background-color: #e5ebea;
}

.tr-odd-color {
  background-color: #edf1f1;
}

.ant-menu-submenu > .ant-menu {
  background-color: $submenu-background-color;
}

.ant-progress-text {
  color: #8185b3;
}

#test-env-banner {
  background-color: yellow;
  text-align: center;
}
