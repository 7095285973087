.ant-btn.danger-link-btn {
  color: #ff4d4f;
}
.ant-btn.danger-link-btn:hover {
  color: #ff8e8f;
}

.modal-confirmed .ant-modal-header {
  background-color: lightgreen;
}
