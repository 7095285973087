
#header {
  padding: 5px 10px 25px 10px;

  #header-left {
    float: left;
  }

  #header-right {
    float: right;
    margin-right: 15px;

    span {
      margin-left: 5px;
      margin-right: 5px;
    }

    .ant-badge {
      font-size: 20px;
      color: white;
    }
    
    .user-menu-item {
      line-height: 30px;
      position: relative;
      display: inline-block;
      font-weight: 400;
      white-space: nowrap;
      text-align: center;
      background-image: none;
      border: 1px solid transparent;
      border-color: transparent;
      box-shadow: none;
      cursor: pointer;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      user-select: none;
      touch-action: manipulation;
      height: 32px;
      padding: 4px 15px;
      font-size: 14px;
      border-radius: 2px;
      background: transparent;
    }
  }
}
