@import '../../styles/variables';

.header-action-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.header-action {
  color: $font-color-primary;
  margin-left: 10px;
}

.ant-btn.header-action:hover {
  background-color: #eeeef1;
}

.ant-btn.header-action:active {
  background-color: #d9d9d9;
}

.header-action.close {
  margin-left: 20px;
}

.header-action-icon {
  color: $font-color-primary;
}
