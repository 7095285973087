
#settings {
  flex: 0 0 auto;
  background-color: white;
  height: 100%;
  width: 600px;
  padding: 10px;

  .react-tel-input {
    .selected-flag {
      padding-top: 10px;
    }
  }


  h3, h4 {
    color: #8185B3;
  }

  .indent {
    padding-left: 40px;
  }
}

.titleRow {
  width: 100%;
  display: flex;
  padding-top: 10px;
  justify-content: left;
  align-self: flex-start;
}

#notificationSettings {
  margin-top: 10px;
  display: flex;
}

.subRow {
  display: flex;
  justify-content: flex-end;
  padding-right: 100px;

  button {
    margin-right: 10px;
  }
}

.tableRow {
  padding-bottom: 20px;
  display: flex;
  justify-content: center;

  #pseudos-table {
    width: 350px;

    td {
      text-align: center;
    }

    th {
      font-weight: bold;
      text-align: center;
    }

    .center {
      text-align: center;
    }

  }

  #notifications-table {
    width: 350px;

    td {
      text-align: right;
    }

    th {
      font-weight: bold;
      text-align: center;
    }

    .center {
      text-align: center;
    }
  }
}

.settings-column {
  display: flex;
  flex-direction: column;
}

.settings-button {
  margin-left: 5px;
}

.editable-field {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.editable-field.active {
  flex-direction: column;
  align-items: start;
}